export const MAIN_COLOR_STRING = '#26a69a';
export const MOBILE_VIEW = 0;
export const PAD_VIEW = 1;
export const PC_VIEW = 2;
export const DEVICE_STATUS = 'device';
export const STATE = 'state';
export const ACCESS_TOKEN_NAME = 'access';
export const TOKEN_NAME = 'token';
export const IP_LOCAL_NAME = 'iplocal';
export const REFRESH_TOKEN_NAME = 'refresh';
export const HOLDER_NAME = 'holder';
export const FULLNAME_NAME = 'name';
export const ROOT_NAME = 'root';
export const HASH_NAME = 'hash';
export const JSON_CONTENT_TYPE = 'application/json';
export const FORM_DATA_CONTENT_TYPE = 'form-data';
export const WARNING_DATA_CONTENT_TYPE = 'warning-data';
export const API_REQUEST_TIMEOUT_MILLISECONDS = 300_000;
export const API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS = -10;
export const VERSION = '1.0';
export const LANGUAGE_RUS = 'rus';
export const LANGUAGE_UZ = 'uz';
export const ACTION_MAGNET_FLOW = 0;
export const ACTION_TEMP = 1;
export const START_PAGE_SIZE_DEVICES = 20;
export const PAGE_SIZE_DEVICES = [20, 50, 100];
export const START_PAGE_SIZE_ARCHIVES = 15;
export const PAGE_SIZE_ARCHIVES = [15, 50, 100];
export const START_PAGE_SIZE_SETUP = 15;
export const PAGE_SIZE_SETUP = [15, 50, 100];
export const SI_NAME = 'siname';
export const MODEL_NAME = 'modelname';
export const PODMODEL_NAME = 'podmodelname';
export const PR_TYPE = 'prtype';

export const API_STATUS_PROBLEM =
  'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже';

export const LEVEL_READ = 1;
export const LEVEL_READ_WRITE = 2;
export const LEVEL_ADMIN = 5;

export const VIEW_ARCHIVE = '0';
export const PDF_ARCHIVE = '1';
export const EXCEL_ARCHIVE = '2';

export const NEO_REG_NUM = '*10043-85*';

export const ROUND_VOLUME = 1000;
export const ROUND_VOLUME_ALL = 1000;
export const ROUND_TEMP = 100;
export const ROUND_PRES = 1000;
export const ROUND_KOMP = 10000;
export const ROUND_TIME_HOUR = 100;
export const ROUND_TEMP_PROC = 100;
export const ROUND_VOLT_PROC = 100;
export const ROUND_BATTERY = 1;

export const SI_TYPE_FLOW = 0;
export const SI_TYPE_PRES = 1;
export const SI_TYPE_TEMP = 3;
export const SI_TYPE_BAR = 5;
export const SI_TYPE_IVK = 6;
export const SI_TYPE_TEMP_NP = 7;
export const SI_TYPE_IVK_ONLY_CALC = 9;
export const SI_TYPE_ALL = 100;

export const TEMP_UNIT_C = 0;
export const TEMP_UNIT_K = 1;

export const PRES_UNIT_PA = 0;
export const PRES_UNIT_KPA = 1;
export const PRES_UNIT_BAR = 2;
export const PRES_UNIT_MPA = 3;
export const PRES_UNIT_KGSM2 = 4;
export const PRES_UNIT_KGSSM2 = 5;
export const PRES_UNIT_MMRTST = 6;
export const PRES_UNIT_MMVODST = 7;

export const VOLUME_UNIT_L = 0;
export const VOLUME_UNIT_M = 1;

export const OUTPUT_4_20MA = 0;
export const OUTPUT_IMPULSNII = 1;
export const OUTPUT_CHASTOTA = 2;
export const OUTPUT_CIFRA = 3;
export const OUTPUT_0_20MA = 4;
export const OUTPUT_1_5V = 5;
export const OUTPUT_SOPR = 6;
export const OUTPUT_MILLVOLT = 7;

export const POGR_NONE = 0;
export const POGR_ABS = 1;
export const POGR_PRIV = 2;
export const POGR_PRIV_MAX = 3;
export const POGR_OTN = 4;
export const POGR_OTN_ST_NEOP = 5;
export const POGR_ABS_MA_4_20 = 6;
export const POGR_ABS_MA_0_20 = 7;
export const POGR_ABS_C = 8;
export const POGR_ABS_OM = 9;
export const POGR_ABS_MV = 10;
export const POGR_ABS_GERC = 11;
export const POGR_ABS_IMP = 12;

export const TS_DOPUSK_NONE = 0;
export const TS_DOPUSK_AA = 1;
export const TS_DOPUSK_A = 2;
export const TS_DOPUSK_B = 3;
export const TS_DOPUSK_C = 4;

export const TEMP_TYPE_TS = 1;
export const TEMP_TYPE_TS_NP = 2;
export const TEMP_TYPE_DT = 3;

export const VID_POGR_MAIN = 0;
export const VID_POGR_DOP_10 = 1;
export const VID_POGR_DOP_1 = 2;
export const VID_POGR_DOP_RAB = 3;
export const VID_POGR_RAB = 4;
export const VID_POGR_CALC = 5;
export const VID_POGR_TIME = 6;
export const VID_POGR_CLASS_DOPUSKA_6651 = 7;

export const SPAN_TYPE_ALL = 1;
export const SPAN_TYPE_2 = 2;
export const SPAN_TYPE_3 = 3;
export const SPAN_TYPE_4 = 4;

export const PEREHODNII_1 = 1;
export const PEREHODNII_2 = 2;
export const PEREHODNII_3 = 3;
export const PEREHODNII_4 = 4;

export const FROM_MAX_DOLI = 0;
export const IN_UNIT = 1;

export const NORM_FORM_IZM = 0;
export const NORM_FORM_OTN_MAX_NASTR = 1;
export const NORM_FORM_OTN_NASTR_MAX = 2;
export const NORM_FORM_RE = 3;
export const NORM_FORM_FLOW_ST = 4;
export const NORM_MAIN_ACCUR = 5;

export const FORM_KX_PLUS_B = 0;
export const FORM_X_POW = 1;
export const FORM_X_EXP = 2;
export const FORM_X_LOG = 3;
export const FORM_X_STEPEN = 4;
export const FORM_X_IRVIS_74731_19 = 5;
export const FORM_X_IRVIS_87977_23 = 6;

export const SI_SPAN_IRVIS_74731_19 = 0;
export const SI_SPAN_IRVIS_87977_23 = 1;
