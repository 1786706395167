import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  getDefaultAlertProps,
  getFormTypesSpan,
  getIdFromArray,
  getModelFromMany,
  getNameArray,
  getNameFromArray,
  getUnitType,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { IAlertProps } from '../../interface/IAlertProps';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  PR_TYPE,
} from '../../constant/constants';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { SpanListDto } from '../../dto/span/SpanLIstDto';
import { apiUpdateSpan } from '../../service/span/apiUpdateSpan';
import { MyTextFiled } from '../UI/MyTextField';
import { MyCheckBox } from '../UI/MyCheckBox';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: SpanListDto;
}

export const DialogSpanMore: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const { parSiId, parModelId, parPodModelId } = useParams();
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const formTypes = getFormTypesSpan();
  const [spanDto, setSpanDto] = useState<SpanListDto>({
    id: -1,
    siId: parseInt(parSiId ?? '-1', 10),
    modelId: getModelFromMany(parModelId, parPodModelId),
    note: '',
    npi: '',
    vpi: '',
    unit: -1,
    isForm: -1,
    formType: -1,
  });
  const units = getUnitType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10)
  );

  const handleClose = () => {
    setSpanDto({
      id: -1,
      siId: parseInt(parSiId ?? '-1', 10),
      modelId: getModelFromMany(parModelId, parPodModelId),
      note: '',
      npi: '',
      vpi: '',
      unit: -1,
      isForm: -1,
      formType: -1,
    });
    onClose();
    setCheckHelper(false);
  };

  const updateSpan = async () => {
    const apiResult: IApiResult = await apiUpdateSpan(spanDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleUpdate = async () => {
    if (
      (spanDto.npi === '' || spanDto.vpi === '' || spanDto.unit === -1) &&
      spanDto.isForm !== 1
    ) {
      setCheckHelper(true);
      return;
    }
    await updateSpan();
  };

  useEffect(() => {
    if (open) {
      setSpanDto({
        id: dto?.id ?? -1,
        siId: dto?.siId ?? -1,
        modelId: dto?.modelId ?? -1,
        note: dto?.note ?? '',
        npi: dto?.npi ?? '',
        vpi: dto?.vpi ?? '',
        unit: dto?.unit ?? -1,
        isForm: dto?.isForm ?? -1,
        formType: dto?.formType ?? -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '500px' },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Редактирование
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack height="100%" spacing={2} justifyContent="space-between">
            <Stack width="100%" direction="column" spacing={1}>
              <MyTextFiled
                value={spanDto.note}
                onChange={(e) =>
                  setSpanDto({
                    ...spanDto,
                    note: e.target.value,
                  })
                }
                type="text"
                label="Примечание (исполнение)"
                size="small"
              />
              {spanDto.isForm !== 1 && (
                <MyAutoComplete
                  value={
                    spanDto.unit !== undefined && spanDto.unit !== -1
                      ? getNameFromArray(units, spanDto.unit)
                      : ''
                  }
                  onChange={(e, v) =>
                    setSpanDto({
                      ...spanDto,
                      unit: getIdFromArray(units, v),
                    })
                  }
                  error={checkHelper}
                  arrayList={getNameArray(units)}
                  size="small"
                  maxHeightDropList="500px"
                  label="Единица измерений"
                />
              )}
              {spanDto.isForm !== 1 && (
                <MyTextFiled
                  value={spanDto.npi}
                  onChange={(e) =>
                    setSpanDto({
                      ...spanDto,
                      npi: e.target.value,
                    })
                  }
                  error={checkHelper}
                  type="number"
                  label="Нижний предел измерений"
                  size="small"
                />
              )}
              {spanDto.isForm !== 1 && (
                <MyTextFiled
                  value={spanDto.vpi}
                  onChange={(e) =>
                    setSpanDto({
                      ...spanDto,
                      vpi: e.target.value,
                    })
                  }
                  error={checkHelper}
                  type="number"
                  label="Верхний предел измерений"
                  size="small"
                />
              )}
              <MyCheckBox
                value={spanDto.isForm === 1}
                onChange={(e) => {
                  setSpanDto({
                    ...spanDto,
                    isForm: e.target.checked ? 1 : 0,
                  });
                }}
                label="В виде формулы"
              />
              {spanDto.isForm === 1 && (
                <MyAutoComplete
                  value={
                    spanDto.formType !== undefined && spanDto.formType !== -1
                      ? getNameFromArray(formTypes, spanDto.formType)
                      : ''
                  }
                  onChange={(e, v) => {
                    setSpanDto({
                      ...spanDto,
                      formType: getIdFromArray(formTypes, v),
                    });
                  }}
                  error={checkHelper}
                  arrayList={getNameArray(formTypes)}
                  size="small"
                  label="Тип формулы"
                />
              )}
            </Stack>
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleUpdate}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogSpanMore.defaultProps = {
  dto: undefined,
};
