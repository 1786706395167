import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Card,
  createTheme,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  descrError,
  getAccurType,
  getCDopuskType,
  getDefaultAlertProps,
  getFormTypesSpan,
  getNameFromArray,
  getOutputType,
  getPerehodniiType,
  getSelectedLng,
  getSpanType,
  getUnitType,
  getVidPogr,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  API_STATUS_PROBLEM,
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  MODEL_NAME,
  PAGE_SIZE_DEVICES,
  PODMODEL_NAME,
  PR_TYPE,
  SI_NAME,
  SPAN_TYPE_ALL,
  START_PAGE_SIZE_DEVICES,
  VID_POGR_CLASS_DOPUSKA_6651,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyButton } from '../UI/MyButton';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { apiGetModel } from '../../service/model/apiGetModel';
import { ModelListDto } from '../../dto/model/ModelLIstDto';
import { apiDeleteModel } from '../../service/model/apiDeleteModel';
import { OutputListDto } from '../../dto/output/OutputLIstDto';
import { SpanListDto } from '../../dto/span/SpanLIstDto';
import { ErrorListDto } from '../../dto/error/ErrorLIstDto';
import { apiGetOutput } from '../../service/output/apiGetOutput';
import { apiGetSpan } from '../../service/span/apiGetSpan';
import { apiGetError } from '../../service/error/apiGetError';
import { DialogCreateModel } from '../dialog/DialogCreateModel';
import { DialogModelMore } from '../dialog/DialogModelMore';
import { DEVICES_ROUTE } from '../../constant/routes';
import { apiDeleteOutput } from '../../service/output/apiDeleteOutput';
import { DialogCreateOutput } from '../dialog/DialogCreateOuptut';
import { DialogOutputMore } from '../dialog/DialogOutputMore';
import { apiDeleteSpan } from '../../service/span/apiDeleteSpan';
import { DialogCreateSpan } from '../dialog/DialogCreateSpan';
import { DialogSpanMore } from '../dialog/DialogSpanMore';
import { apiDeleteError } from '../../service/error/apiDeleteError';
import { DialogCreateError } from '../dialog/DialogCreateError';
import { DialogMoreError } from '../dialog/DialogMoreError';

export const ModelsDiv = () => {
  const { parSiId, parModelId, parPodModelId } = useParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [modelDtos, setModelDtos] = useState<ModelListDto[]>([]);
  const [outputDtos, setOutputDtos] = useState<OutputListDto[]>([]);
  const [spanDtos, setSpanDtos] = useState<SpanListDto[]>([]);
  const [errorDtos, setErrorDtos] = useState<ErrorListDto[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogModelMore, setOpenDialogMOdelMore] = useState(false);
  const [openDialogOutput, setOpenDialogOutput] = useState(false);
  const [openDialogModelOutput, setOpenDialogOutputMore] = useState(false);
  const [openDialogSpan, setOpenDialogSpan] = useState(false);
  const [openDialogSpanMore, setOpenDialogSpanMore] = useState(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [openDialogErrorMore, setOpenDialogErrorMore] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogConfirmationOutput, setOpenDialogConfirmationOutput] =
    useState(false);
  const [openDialogConfirmationSpan, setOpenDialogConfirmationSpan] =
    useState(false);
  const [openDialogConfirmationError, setOpenDialogConfirmationError] =
    useState(false);

  const outputTypes = getOutputType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10)
  );
  const units = getUnitType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10)
  );
  const types = getVidPogr(parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10));
  const accurTypes = getAccurType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
    false
  );
  const formTypes = getFormTypesSpan();
  const spanTypes = getSpanType();
  const dopuskTypes = getCDopuskType();

  const [modelDelete, setModelDeleteDto] = useState<ModelListDto>({
    id: -1,
    siId: -1,
    name: '',
    modelId: -1,
  });
  const [outputDelete, setOutputDeleteDto] = useState<OutputListDto>({
    id: -1,
    siId: -1,
    modelId: -1,
    outputType: -1,
  });
  const [spanDelete, setSpanDeleteDto] = useState<SpanListDto>({
    id: -1,
    siId: -1,
    modelId: -1,
    note: '',
    npi: '',
    vpi: '',
    unit: -1,
    isForm: -1,
    formType: -1,
  });
  const [errorDelete, setErrorDeleteDto] = useState<ErrorListDto>({
    id: -1,
    type: -1,
    siId: -1,
    modelId: -1,
    spanType: -1,
    dopusk: -1,
    note: '',
    npi: '',
    vpi: '',
    unitPerehodnii: -1,
    perehodnayaTohcka: '',
    data: [
      {
        id: 1,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
      {
        id: 2,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
      {
        id: 3,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
      {
        id: 4,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
    ],
  });

  let title = localStorage.getItem(SI_NAME) || '';
  const titleModel = localStorage.getItem(MODEL_NAME) || '';
  const titlePodModel = localStorage.getItem(PODMODEL_NAME) || '';
  title =
    parModelId === undefined
      ? title
      : `${title} *##* ${titleModel}${
          parPodModelId !== undefined ? `*##*${titlePodModel}` : ''
        }`;

  const navigateToModel = (dto: ModelListDto) => {
    localStorage.setItem(MODEL_NAME, `${dto.name}`);
    navigate(`${DEVICES_ROUTE}/${parSiId}/${dto.id}`);
  };

  const navigateToPodModel = (dto: ModelListDto) => {
    localStorage.setItem(PODMODEL_NAME, `${dto.name}`);
    navigate(`${DEVICES_ROUTE}/${parSiId}/${parModelId}/${dto.id}`);
  };

  const getModels = async (siId: string, modelId?: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetModel(siId, modelId);
    if (apiResult.isSuccess) {
      setModelDtos(apiResult.data.model);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getOuputs = async (siId: string, modelId?: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetOutput(siId, modelId);
    if (apiResult.isSuccess) {
      setOutputDtos(apiResult.data.output);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getSpans = async (siId: string, modelId?: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetSpan(siId, modelId);
    if (apiResult.isSuccess) {
      setSpanDtos(apiResult.data.span);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getErrors = async (siId: string, modelId?: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetError(siId, modelId);
    if (apiResult.isSuccess) {
      setErrorDtos(apiResult.data.error);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const deleteModel = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const dto: ModelListDto = modelDelete;
    const apiResult: IApiResult = await apiDeleteModel(dto);
    if (apiResult.isSuccess) {
      await getModels(
        parSiId ?? '-1',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: ModelListDto) => {
    setModelDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const deleteOutput = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationOutput(false);
      return;
    }
    const dto: OutputListDto = outputDelete;
    const apiResult: IApiResult = await apiDeleteOutput(dto);
    if (apiResult.isSuccess) {
      await getOuputs(
        parSiId ?? '-1',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationOutput(false);
  };

  const confirmDeleteOutput = (dto: OutputListDto) => {
    setOutputDeleteDto(dto);
    setOpenDialogConfirmationOutput(true);
  };

  const deleteSpan = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationSpan(false);
      return;
    }
    const dto: SpanListDto = spanDelete;
    const apiResult: IApiResult = await apiDeleteSpan(dto);
    if (apiResult.isSuccess) {
      await getSpans(
        parSiId ?? '-1',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationSpan(false);
  };

  const confirmDeleteSpan = (dto: SpanListDto) => {
    setSpanDeleteDto(dto);
    setOpenDialogConfirmationSpan(true);
  };

  const deleteError = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmationError(false);
      return;
    }
    const dto: ErrorListDto = errorDelete;
    const apiResult: IApiResult = await apiDeleteError(dto);
    if (apiResult.isSuccess) {
      await getErrors(
        parSiId ?? '-1',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmationError(false);
  };

  const confirmDeleteError = (dto: ErrorListDto) => {
    setErrorDeleteDto(dto);
    setOpenDialogConfirmationError(true);
  };

  const getMoreInformation = (dto: ModelListDto) => {
    setModelDeleteDto(dto);
    setOpenDialogMOdelMore(true);
  };

  const getMoreInformationSpan = (dto: SpanListDto) => {
    setSpanDeleteDto(dto);
    setOpenDialogSpanMore(true);
  };

  const getMoreInformationOutput = (dto: OutputListDto) => {
    setOutputDeleteDto(dto);
    setOpenDialogOutputMore(true);
  };

  const getMoreInformationError = (dto: ErrorListDto) => {
    setErrorDeleteDto(dto);
    setOpenDialogErrorMore(true);
  };

  useEffect(() => {
    if (
      !openDialog &&
      !openDialogCreate &&
      !openDialogModelMore &&
      !openDialogOutput &&
      !openDialogModelOutput &&
      !openDialogSpan &&
      !openDialogSpanMore &&
      !openDialogError &&
      !openDialogErrorMore
    ) {
      getModels(
        parSiId ?? '',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
      getOuputs(
        parSiId ?? '',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
      getSpans(
        parSiId ?? '',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
      getErrors(
        parSiId ?? '',
        parPodModelId !== undefined ? parPodModelId : parModelId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parSiId,
    parModelId,
    parPodModelId,
    openDialog,
    openDialogCreate,
    openDialogModelMore,
    openDialogOutput,
    openDialogModelOutput,
    openDialogSpan,
    openDialogSpanMore,
    openDialogError,
    openDialogErrorMore,
  ]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Наименование модели',
      renderHeader: () => <strong>Наименование модели</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: parPodModelId === undefined ? 105 : 75,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          {parPodModelId === undefined && (
            <IconButton
              title="Просмотр информации"
              size="small"
              onClick={() =>
                parModelId !== undefined
                  ? navigateToPodModel(row.row)
                  : navigateToModel(row.row)
              }
            >
              <FindInPageIcon fontSize="small" sx={{ color: '#9c27b0' }} />
            </IconButton>
          )}
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: '#ff9800' }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDelete(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: '#ff5722' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsOutput: GridColDef[] = [
    {
      field: 'outputType',
      headerName: 'Выходной сигнал',
      renderHeader: () => <strong>Выходной сигнал</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => getNameFromArray(outputTypes, row.row.outputType),
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 70,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getMoreInformationOutput(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: '#ff9800' }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDeleteOutput(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: '#ff5722' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsSpan: GridColDef[] = [
    {
      field: 'note',
      headerName: 'Примечание (исполнение)',
      renderHeader: () => <strong>Примечание (исполнение)</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.note,
    },
    {
      field: 'npi',
      headerName: 'Нижний предел',
      renderHeader: () => <strong>Нижний предел</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) =>
        row.row.isForm !== 1 ? row.row.npi : 'Формула расчета',
    },
    {
      field: 'vpi',
      headerName: 'Верхний предел',
      renderHeader: () => <strong>Верхний предел</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) =>
        row.row.isForm !== 1
          ? row.row.vpi
          : getNameFromArray(formTypes, row.row.formType),
    },
    {
      field: 'unit',
      headerName: 'Единица измерений',
      renderHeader: () => <strong>Единица измерений</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => getNameFromArray(units, row.row.unit),
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 70,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getMoreInformationSpan(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: '#ff9800' }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDeleteSpan(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: '#ff5722' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsError: GridColDef[] = [
    {
      field: 'note',
      headerName: 'Примечание (исполнение)',
      renderHeader: () => <strong>Примечание (исполнение)</strong>,
      flex: 1.5,
      minWidth: 350,
      editable: false,
      renderCell: (row) => row.row.note,
    },
    {
      field: 'type',
      headerName: 'Вид',
      renderHeader: () => <strong>Вид</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => getNameFromArray(types, row.row.type),
    },
    {
      field: 'error',
      headerName: 'Погрешность',
      renderHeader: () => <strong>Погрешность</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) =>
        row.row.type !== VID_POGR_CLASS_DOPUSKA_6651
          ? descrError(row.row)
          : getNameFromArray(dopuskTypes, row.row.dopusk),
    },
    {
      field: 'spanType',
      headerName: '',
      renderHeader: () => <strong> </strong>,
      minWidth: 250,
      editable: false,
      renderCell: (row) =>
        row.row.spanType === SPAN_TYPE_ALL
          ? getNameFromArray(spanTypes, row.row.spanType)
          : `Переходные точки ${row.row.perehodnayaTohcka}`,
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 70,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getMoreInformationError(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: '#ff9800' }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDeleteError(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: '#ff5722' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type: number) => {
    if (type === 0) {
      setOpenDialogCreate(true);
    }
    if (type === 1) {
      setOpenDialogOutput(true);
    }
    if (type === 2) {
      setOpenDialogSpan(true);
    }
    if (type === 3) {
      setOpenDialogError(true);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        marginBottom="20px"
        spacing={2}
      >
        <Stack width="90%">
          <BodyHeaderDiv title={title} />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <div>
              <MyButton
                text="Добавить"
                background={MAIN_COLOR_STRING}
                onClick={handleClick}
              />
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleClose(0)}>Модель</MenuItem>
                <MenuItem onClick={() => handleClose(1)}>
                  Выходной сигнал
                </MenuItem>
                <MenuItem onClick={() => handleClose(2)}>Диапазон</MenuItem>
                <MenuItem onClick={() => handleClose(3)}>Погрешность</MenuItem>
              </Menu>
            </div>
          </Stack>
        </Stack>
        <Stack width="90%" spacing={1}>
          <Grid container spacing={1} marginBottom="10px">
            <Grid
              item
              xs={modelDtos.length > 0 && outputDtos.length > 0 ? 6 : 12}
            >
              {modelDtos.length > 0 && (
                <Card
                  style={{
                    padding: '20px',
                    background: '#f5f5f5',
                  }}
                >
                  <Box
                    height="100%"
                    sx={{
                      '& .app-theme-cell': {
                        fontFamily: 'sans-serif',
                      },
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          background: '#f5f5f5',
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                              fontSize: '100px',
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columns}
                        rows={modelDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={PAGE_SIZE_DEVICES}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </Card>
              )}
            </Grid>
            <Grid
              item
              xs={modelDtos.length > 0 && outputDtos.length > 0 ? 6 : 12}
            >
              {outputDtos.length > 0 && (
                <Card
                  style={{
                    padding: '20px',
                    background: '#f5f5f5',
                  }}
                >
                  <Box
                    height="100%"
                    sx={{
                      '& .app-theme-cell': {
                        fontFamily: 'sans-serif',
                      },
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          background: '#f5f5f5',
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                              fontSize: '100px',
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columnsOutput}
                        rows={outputDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={PAGE_SIZE_DEVICES}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              {spanDtos.length > 0 && (
                <Card
                  style={{
                    padding: '20px',
                    background: '#f5f5f5',
                  }}
                >
                  <Box
                    height="100%"
                    sx={{
                      '& .app-theme-cell': {
                        fontFamily: 'sans-serif',
                      },
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          background: '#f5f5f5',
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                              fontSize: '100px',
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columnsSpan}
                        rows={spanDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={PAGE_SIZE_DEVICES}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              {errorDtos.length > 0 && (
                <Card style={{ padding: '20px', background: '#f5f5f5' }}>
                  <Box
                    height="100%"
                    sx={{
                      '& .app-theme-cell': {
                        fontFamily: 'sans-serif',
                      },
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{
                          background: '#f5f5f5',
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                              py: 1,
                              fontSize: '100px',
                            },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            {
                              py: '5px',
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                              outline: 'none !important',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        columns={columnsError}
                        rows={errorDtos}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...other}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={PAGE_SIZE_DEVICES}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </ThemeProvider>
                  </Box>
                </Card>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <DialogCreateModel
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogModelMore
        open={openDialogModelMore}
        dto={modelDelete}
        onClose={() => setOpenDialogMOdelMore(false)}
      />
      <DialogConfirmation open={openDialogConfirmation} onClose={deleteModel} />

      <DialogCreateOutput
        open={openDialogOutput}
        onClose={() => setOpenDialogOutput(false)}
      />
      <DialogOutputMore
        open={openDialogModelOutput}
        dto={outputDelete}
        onClose={() => setOpenDialogOutputMore(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmationOutput}
        onClose={deleteOutput}
      />

      <DialogCreateSpan
        open={openDialogSpan}
        onClose={() => setOpenDialogSpan(false)}
      />
      <DialogSpanMore
        open={openDialogSpanMore}
        dto={spanDelete}
        onClose={() => setOpenDialogSpanMore(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmationSpan}
        onClose={deleteSpan}
      />

      <DialogCreateError
        open={openDialogError}
        onClose={() => setOpenDialogError(false)}
      />
      <DialogMoreError
        open={openDialogErrorMore}
        dto={errorDelete}
        onClose={() => setOpenDialogErrorMore(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmationError}
        onClose={deleteError}
      />
    </div>
  );
};
