import React, { FunctionComponent, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  getDefaultAlertProps,
  getIdFromArray,
  getNameArray,
  getNameFromArray,
  getPreobrType,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyTextFiled } from '../UI/MyTextField';
import { MyButton } from '../UI/MyButton';
import { IAlertProps } from '../../interface/IAlertProps';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
} from '../../constant/constants';
import { apiAddReestr } from '../../service/device/apiAddReestr';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { ReestrListDto } from '../../dto/reestr/ReestrLIstDto';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCreateSi: FunctionComponent<Props> = ({ open, onClose }) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const preobrTypes = getPreobrType();
  const [reeatrAdd, setReestAdd] = useState<ReestrListDto>({
    id: -1,
    fifoei: '',
    type: -1,
    name: '',
    year: '',
    mpi: '',
    tempType: -1,
    datetimeChangeTs: -1,
    datettimeCreateTs: -1,
  });

  const handleClose = () => {
    setReestAdd({
      id: -1,
      fifoei: '',
      type: -1,
      name: '',
      year: '',
      mpi: '',
      tempType: -1,
      datetimeChangeTs: -1,
      datettimeCreateTs: -1,
    });
    onClose();
    setCheckHelper(false);
  };

  const addReestr = async () => {
    const apiResult: IApiResult = await apiAddReestr(reeatrAdd);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleAdd = async () => {
    if (reeatrAdd.fifoei === '' || reeatrAdd.name === '') {
      setCheckHelper(true);
      return;
    }
    await addReestr();
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '500px' },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Новый преобразователь
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2} height="100%">
            <Stack width="100%" direction="row" spacing={1}>
              <MyAutoComplete
                value={
                  reeatrAdd.type !== undefined && reeatrAdd.type !== -1
                    ? getNameFromArray(preobrTypes, reeatrAdd.type)
                    : ''
                }
                onChange={(e, v) =>
                  setReestAdd({
                    ...reeatrAdd,
                    type: getIdFromArray(preobrTypes, v),
                  })
                }
                error={checkHelper}
                arrayList={getNameArray(preobrTypes)}
                size="small"
                maxHeightDropList="500px"
                label="Измерительный преобразователь"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Информация о средстве измерений
              </Typography>
            </Stack>
            <Stack width="100%" direction="column" spacing={1}>
              <MyTextFiled
                value={reeatrAdd.fifoei}
                onChange={(e) =>
                  setReestAdd({
                    ...reeatrAdd,
                    fifoei: e.target.value,
                  })
                }
                error={checkHelper}
                label="№ ФИФОЕИ"
                size="small"
              />
              <MyTextFiled
                value={reeatrAdd.name}
                onChange={(e) =>
                  setReestAdd({
                    ...reeatrAdd,
                    name: e.target.value,
                  })
                }
                error={checkHelper}
                label="Наименование"
                size="small"
              />
              <MyTextFiled
                value={reeatrAdd.mpi}
                onChange={(e) =>
                  setReestAdd({
                    ...reeatrAdd,
                    mpi: e.target.value,
                  })
                }
                error={checkHelper}
                label="Интервал между поверками"
                size="small"
              />
            </Stack>
            <div style={{ flex: 1 }}> </div>
            <MyButton
              text="Добавить"
              background={MAIN_COLOR_STRING}
              onClick={handleAdd}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
