import React, { FunctionComponent, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import {
  getAccurType,
  getFormTypes,
  getIdFromArray,
  getNameArray,
  getNameFromArray,
  getNormPreobraz,
  getPoddiapazon,
} from '../../utils/utils';
import {
  FORM_X_IRVIS_74731_19,
  PR_TYPE,
  SPAN_TYPE_ALL,
  VID_POGR_CLASS_DOPUSKA_6651,
} from '../../constant/constants';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { MyTextFiled } from '../UI/MyTextField';
import { ErrorItemDto, ErrorListDto } from '../../dto/error/ErrorLIstDto';
import { MyCheckBox } from '../UI/MyCheckBox';

interface Props {
  perehodnieTochki: string;
  index: number;
  cnt: number;
  errorDto: ErrorListDto;
  dto: ErrorItemDto;
  handle1: (e: any, id: number) => void;
  handle2: (e: any, id: number) => void;
  handle3: (e: any, id: number) => void;
  handle4: (e: any, id: number) => void;
  handle5: (e: any, id: number) => void;
  handle6: (e: any, id: number) => void;
}

export const ErrorItemDiv: FunctionComponent<Props> = ({
  perehodnieTochki,
  index,
  cnt,
  errorDto,
  dto,
  handle1,
  handle2,
  handle3,
  handle4,
  handle5,
  handle6,
}) => {
  const [errorItemDto, setErrorItemDto] = useState<ErrorItemDto>(dto);
  const [checkHelper, setCheckHelper] = useState(false);
  const normForms = getNormPreobraz();
  const formTypes = getFormTypes();

  console.log(`${index} ${cnt}`);
  return (
    <div>
      <Stack direction="column" spacing={1}>
        <Stack alignItems="center" direction="row" spacing={1}>
          {errorDto.spanType !== SPAN_TYPE_ALL && (
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              {`Поддиапазон ${errorItemDto.id}. Диапазон ${getPoddiapazon(
                perehodnieTochki,
                index,
                cnt
              )}`}
            </Typography>
          )}
          {errorDto.type !== VID_POGR_CLASS_DOPUSKA_6651 && (
            <MyCheckBox
              value={errorItemDto.isForm === 1}
              onChange={(e) => {
                setErrorItemDto({
                  ...errorItemDto,
                  isForm: e.target.checked ? 1 : 0,
                });
                handle2(e.target.checked ? 1 : 0, dto.id);
              }}
              label="В виде формулы"
            />
          )}
        </Stack>
        {errorDto.spanType !== SPAN_TYPE_ALL && <Divider />}
        <Stack direction="row" spacing={1}>
          <MyAutoComplete
            value={
              errorItemDto.errorType !== undefined &&
              errorItemDto.errorType !== -1
                ? getNameFromArray(
                    getAccurType(
                      parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                      false
                    ),
                    errorItemDto.errorType
                  )
                : ''
            }
            onChange={(e, v) => {
              setErrorItemDto({
                ...errorItemDto,
                errorType: getIdFromArray(
                  getAccurType(
                    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                    false
                  ),
                  v
                ),
              });
              handle1(
                getIdFromArray(
                  getAccurType(
                    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                    false
                  ),
                  v
                ),
                dto.id
              );
            }}
            error={checkHelper}
            arrayList={getNameArray(
              getAccurType(
                parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
                false
              )
            )}
            size="small"
            maxHeightDropList="500px"
            label="Тип погрешности"
          />
          {errorDto.type !== VID_POGR_CLASS_DOPUSKA_6651 &&
            errorItemDto.isForm !== 1 && (
              <MyTextFiled
                value={errorItemDto.error}
                onChange={(e) => {
                  setErrorItemDto({
                    ...errorItemDto,
                    error: e.target.value,
                  });
                  handle6(e.target.value, dto.id);
                }}
                error={checkHelper}
                type={errorDto.spanType === SPAN_TYPE_ALL ? 'number' : 'text'}
                label="Погрешность"
                size="small"
              />
            )}
          {errorItemDto.isForm === 1 && (
            <MyAutoComplete
              value={
                errorItemDto.formType !== undefined &&
                errorItemDto.formType !== -1
                  ? getNameFromArray(formTypes, errorItemDto.formType)
                  : ''
              }
              onChange={(e, v) => {
                setErrorItemDto({
                  ...errorItemDto,
                  formType: getIdFromArray(formTypes, v),
                });
                handle3(getIdFromArray(formTypes, v), dto.id);
              }}
              error={checkHelper}
              arrayList={getNameArray(formTypes)}
              size="small"
              label="Тип формулы"
            />
          )}
        </Stack>
        {errorItemDto.isForm === 1 &&
          errorItemDto.formType !== FORM_X_IRVIS_74731_19 && (
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <MyAutoComplete
                  value={
                    errorItemDto.normirovka !== undefined &&
                    errorItemDto.normirovka !== -1
                      ? getNameFromArray(normForms, errorItemDto.normirovka)
                      : ''
                  }
                  onChange={(e, v) => {
                    setErrorItemDto({
                      ...errorItemDto,
                      normirovka: getIdFromArray(normForms, v),
                    });
                    handle4(getIdFromArray(normForms, v), dto.id);
                  }}
                  error={checkHelper}
                  arrayList={getNameArray(normForms)}
                  size="small"
                  label="Значение x"
                />
                <MyTextFiled
                  value={errorItemDto.formKoeff}
                  onChange={(e) => {
                    setErrorItemDto({
                      ...errorItemDto,
                      formKoeff: e.target.value,
                    });
                    handle5(e.target.value, dto.id);
                  }}
                  error={checkHelper}
                  type="text"
                  label="Коэффициенты k0, k1 ... (через ;)"
                  size="small"
                />
              </Stack>
            </Stack>
          )}
      </Stack>
    </div>
  );
};
