import { Card, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { FunctionComponent } from 'react';

interface Props {
  title: string;
}

export const BodyHeaderDiv: FunctionComponent<Props> = ({ title }) => {
  const { t } = useTranslation();

  const titleNeww = title.split('*##*');

  return (
    <div>
      <Stack
        style={{
          padding: '10px 0px 10px 0px',
          marginTop: '20px',
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {titleNeww.length > 0 && (
              <IconButton
                size="large"
                sx={{ color: '#9c27b0' }}
                onClick={() => window.history.back()}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
            {titleNeww.map((item, index) => (
              <Card
                style={{
                  padding: '10px',
                  background:
                    index === titleNeww.length - 1 ? '#9c27b0' : '#9e9e9e',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: '#ffffff',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {item}
                </Typography>
              </Card>
            ))}
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    </div>
  );
};
