import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  getAccurType,
  getCDopuskType,
  getDefaultAlertProps,
  getIdFromArray,
  getModelFromMany,
  getNameArray,
  getNameFromArray,
  getPerehodniiType,
  getSpanType,
  getUnitType,
  getVidPogr,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyButton } from '../UI/MyButton';
import { IAlertProps } from '../../interface/IAlertProps';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  PR_TYPE,
  SPAN_TYPE_ALL,
  VID_POGR_CLASS_DOPUSKA_6651,
  VID_POGR_DOP_1,
  VID_POGR_DOP_10,
} from '../../constant/constants';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { MyTextFiled } from '../UI/MyTextField';
import { ErrorListDto } from '../../dto/error/ErrorLIstDto';
import { apiUpdateError } from '../../service/error/apiUpdateError';
import { MyCheckBox } from '../UI/MyCheckBox';
import { ErrorItemDiv } from './ErrorItemDiv';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: ErrorListDto;
}

export const DialogMoreError: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const { parSiId, parModelId, parPodModelId } = useParams();
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [errorDto, setErrorDto] = useState<ErrorListDto>({
    id: -1,
    type: -1,
    siId: parseInt(parSiId ?? '-1', 10),
    modelId: getModelFromMany(parModelId, parPodModelId),
    spanType: 1,
    dopusk: -1,
    note: '',
    npi: '',
    vpi: '',
    unitPerehodnii: -1,
    perehodnayaTohcka: '',
    data: [
      {
        id: 1,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
      {
        id: 2,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
      {
        id: 3,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
      {
        id: 4,
        errorType: -1,
        error: '',
        formType: -1,
        formKoeff: '',
        isForm: -1,
        normirovka: -1,
      },
    ],
  });
  const dopuskTypes = getCDopuskType();
  const types = getVidPogr(parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10));
  const spanTypes = getSpanType();
  const perehodniiType = getPerehodniiType();
  const units = getUnitType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10)
  );

  const handleClose = () => {
    setErrorDto({
      id: -1,
      type: -1,
      siId: parseInt(parSiId ?? '-1', 10),
      modelId: getModelFromMany(parModelId, parPodModelId),
      spanType: 1,
      dopusk: -1,
      note: '',
      npi: '',
      vpi: '',
      unitPerehodnii: -1,
      perehodnayaTohcka: '',
      data: [
        {
          id: 1,
          errorType: -1,
          error: '',
          formType: -1,
          formKoeff: '',
          isForm: -1,
          normirovka: -1,
        },
        {
          id: 2,
          errorType: -1,
          error: '',
          formType: -1,
          formKoeff: '',
          isForm: -1,
          normirovka: -1,
        },
        {
          id: 3,
          errorType: -1,
          error: '',
          formType: -1,
          formKoeff: '',
          isForm: -1,
          normirovka: -1,
        },
        {
          id: 4,
          errorType: -1,
          error: '',
          formType: -1,
          formKoeff: '',
          isForm: -1,
          normirovka: -1,
        },
      ],
    });
    onClose();
    setCheckHelper(false);
  };

  const updateError = async () => {
    const apiResult: IApiResult = await apiUpdateError(errorDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleUpdate = async () => {
    if (errorDto.type === -1) {
      setCheckHelper(true);
      return;
    }
    await updateError();
  };

  const handle1 = (e: any, id: number) => {
    for (let i = 0; i < errorDto.data.length; i += 1) {
      if (errorDto.data[i].id === id) {
        errorDto.data[i].errorType = e;
      }
    }
  };

  const handle2 = (e: any, id: number) => {
    for (let i = 0; i < errorDto.data.length; i += 1) {
      if (errorDto.data[i].id === id) {
        errorDto.data[i].isForm = e;
      }
    }
  };

  const handle3 = (e: any, id: number) => {
    for (let i = 0; i < errorDto.data.length; i += 1) {
      if (errorDto.data[i].id === id) {
        errorDto.data[i].formType = e;
      }
    }
  };

  const handle4 = (e: any, id: number) => {
    for (let i = 0; i < errorDto.data.length; i += 1) {
      if (errorDto.data[i].id === id) {
        errorDto.data[i].normirovka = e;
      }
    }
  };

  const handle5 = (e: any, id: number) => {
    for (let i = 0; i < errorDto.data.length; i += 1) {
      if (errorDto.data[i].id === id) {
        errorDto.data[i].formKoeff = e;
      }
    }
  };

  const handle6 = (e: any, id: number) => {
    for (let i = 0; i < errorDto.data.length; i += 1) {
      if (errorDto.data[i].id === id) {
        errorDto.data[i].error = e;
      }
    }
  };

  useEffect(() => {
    if (open) {
      setErrorDto({
        id: dto?.id ?? -1,
        type: dto?.type ?? -1,
        siId: parseInt(parSiId ?? '-1', 10),
        modelId: getModelFromMany(parModelId, parPodModelId),
        spanType: dto?.spanType ?? 1,
        dopusk: dto?.dopusk ?? -1,
        note: dto?.note ?? '',
        npi: dto?.npi ?? '',
        vpi: dto?.vpi ?? '',
        unitPerehodnii: dto?.unitPerehodnii ?? -1,
        perehodnayaTohcka: dto?.perehodnayaTohcka ?? '',
        data: dto?.data ?? [
          {
            id: 1,
            errorType: -1,
            error: '',
            formType: -1,
            formKoeff: '',
            isForm: -1,
            normirovka: -1,
          },
          {
            id: 2,
            errorType: -1,
            error: '',
            formType: -1,
            formKoeff: '',
            isForm: -1,
            normirovka: -1,
          },
          {
            id: 3,
            errorType: -1,
            error: '',
            formType: -1,
            formKoeff: '',
            isForm: -1,
            normirovka: -1,
          },
          {
            id: 4,
            errorType: -1,
            error: '',
            formType: -1,
            formKoeff: '',
            isForm: -1,
            normirovka: -1,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: {
            backgroundColor: '#e7ebee',
            width: '100%',
            height: errorDto.spanType === SPAN_TYPE_ALL ? '700px' : '100%',
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Редактировать
              </Typography>
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2} height="100%" justifyContent="space-between">
            <Stack width="100%" direction="column" spacing={1}>
              <MyTextFiled
                value={errorDto.note}
                onChange={(e) =>
                  setErrorDto({
                    ...errorDto,
                    note: e.target.value,
                  })
                }
                type="text"
                multiLine
                label="Примечание (исполнение)"
                size="small"
              />
              <MyAutoComplete
                value={
                  errorDto.type !== undefined && errorDto.type !== -1
                    ? getNameFromArray(types, errorDto.type)
                    : ''
                }
                onChange={(e, v) =>
                  setErrorDto({
                    ...errorDto,
                    type: getIdFromArray(types, v),
                  })
                }
                error={checkHelper}
                arrayList={getNameArray(types)}
                size="small"
                maxHeightDropList="500px"
                label="Вид"
              />
              {(errorDto.type === VID_POGR_DOP_10 ||
                errorDto.type === VID_POGR_DOP_1) && (
                <Stack direction="row" spacing={1}>
                  <MyTextFiled
                    value={errorDto.npi}
                    onChange={(e) =>
                      setErrorDto({
                        ...errorDto,
                        npi: e.target.value,
                      })
                    }
                    type="number"
                    label="Нижний предел условий поверки, °С"
                    size="small"
                  />
                  <MyTextFiled
                    value={errorDto.vpi}
                    onChange={(e) =>
                      setErrorDto({
                        ...errorDto,
                        vpi: e.target.value,
                      })
                    }
                    type="number"
                    label="Верхний предел условий поверки, °С"
                    size="small"
                  />
                </Stack>
              )}
              <Stack direction="row" spacing={1}>
                <MyAutoComplete
                  value={
                    errorDto.spanType !== undefined && errorDto.spanType !== -1
                      ? getNameFromArray(spanTypes, errorDto.spanType)
                      : ''
                  }
                  onChange={(e, v) =>
                    setErrorDto({
                      ...errorDto,
                      spanType: getIdFromArray(spanTypes, v),
                    })
                  }
                  error={checkHelper}
                  arrayList={getNameArray(spanTypes)}
                  size="small"
                  label=""
                />
                {errorDto.spanType !== SPAN_TYPE_ALL && (
                  <MyAutoComplete
                    value={
                      errorDto.unitPerehodnii !== undefined &&
                      errorDto.unitPerehodnii !== -1
                        ? getNameFromArray(
                            perehodniiType,
                            errorDto.unitPerehodnii
                          )
                        : ''
                    }
                    onChange={(e, v) =>
                      setErrorDto({
                        ...errorDto,
                        unitPerehodnii: getIdFromArray(perehodniiType, v),
                      })
                    }
                    error={checkHelper}
                    arrayList={getNameArray(perehodniiType)}
                    size="small"
                    label="Единица измерений переходной точки"
                  />
                )}
                {errorDto.type !== VID_POGR_CLASS_DOPUSKA_6651 &&
                  errorDto.spanType !== SPAN_TYPE_ALL && (
                    <MyTextFiled
                      value={errorDto.perehodnayaTohcka}
                      onChange={(e) =>
                        setErrorDto({
                          ...errorDto,
                          perehodnayaTohcka: e.target.value,
                        })
                      }
                      error={checkHelper}
                      type="text"
                      label="Переходные точки (через ;)"
                      size="small"
                    />
                  )}
              </Stack>
              {errorDto.type === VID_POGR_CLASS_DOPUSKA_6651 && (
                <MyAutoComplete
                  value={
                    errorDto.dopusk !== undefined && errorDto.dopusk !== -1
                      ? getNameFromArray(dopuskTypes, errorDto.dopusk)
                      : ''
                  }
                  onChange={(e, v) =>
                    setErrorDto({
                      ...errorDto,
                      dopusk: getIdFromArray(dopuskTypes, v),
                    })
                  }
                  error={checkHelper}
                  arrayList={getNameArray(dopuskTypes)}
                  size="small"
                  label="Класс допуска"
                />
              )}
              {errorDto.data !== undefined &&
                errorDto.type !== VID_POGR_CLASS_DOPUSKA_6651 &&
                errorDto.data.map(
                  (item, index) =>
                    errorDto.spanType >= item.id && (
                      <ErrorItemDiv
                        perehodnieTochki={errorDto.perehodnayaTohcka}
                        index={index}
                        cnt={errorDto.perehodnayaTohcka.split(';').length + 1}
                        errorDto={errorDto}
                        dto={item}
                        handle1={handle1}
                        handle2={handle2}
                        handle3={handle3}
                        handle4={handle4}
                        handle5={handle5}
                        handle6={handle6}
                      />
                    )
                )}
            </Stack>
            <MyButton
              text="Сохранить"
              background={MAIN_COLOR_STRING}
              onClick={handleUpdate}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogMoreError.defaultProps = {
  dto: undefined,
};
