import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Card,
  createTheme,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  formatReestr,
  getDefaultAlertProps,
  getPreobrType,
  getSelectedLng,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  API_STATUS_PROBLEM,
  DEVICE_STATUS,
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  PR_TYPE,
  SI_NAME,
  SI_TYPE_ALL,
  SI_TYPE_BAR,
  SI_TYPE_FLOW,
  SI_TYPE_IVK,
  SI_TYPE_IVK_ONLY_CALC,
  SI_TYPE_PRES,
  SI_TYPE_TEMP,
  SI_TYPE_TEMP_NP,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyButton } from '../UI/MyButton';
import { apiDeleteReestr } from '../../service/device/apiDeleteReestr';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { apiGetReestr } from '../../service/device/apiGetReestr';
import { ReestrListDto } from '../../dto/reestr/ReestrLIstDto';
import { DialogCreateSi } from '../dialog/DialogCreateSi';
import { DialogSiMoreInfo } from '../dialog/DialogSiMoreInfo';
import { DEVICES_ROUTE } from '../../constant/routes';

export const DevicesDiv = () => {
  const [value, setValue] = React.useState(SI_TYPE_ALL);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { search } = useParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const [reestrDelete, setReestrDeleteDto] = useState<ReestrListDto>({
    id: -1,
    fifoei: '',
    type: -1,
    name: '',
    year: '',
    tempType: -1,
    datetimeChangeTs: -1,
    datettimeCreateTs: -1,
  });

  const title = localStorage.getItem(DEVICE_STATUS) || '';

  const getDevices = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetReestr('', search);
    if (apiResult.isSuccess) {
      setDeviceDtos(formatReestr(apiResult.data.reestr, value));
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const deleteReestr = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const dto: ReestrListDto = reestrDelete;
    const apiResult: IApiResult = await apiDeleteReestr(dto);
    if (apiResult.isSuccess) {
      await getDevices();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: ReestrListDto) => {
    setReestrDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const getMoreInformation = (dto: ReestrListDto) => {
    setReestrDeleteDto(dto);
    setOpenDialog(true);
  };

  const navigateToModel = (dto: ReestrListDto) => {
    localStorage.setItem(SI_NAME, `${dto.name}, ${dto.fifoei}`);
    localStorage.setItem(PR_TYPE, dto.type.toString());
    navigate(`${DEVICES_ROUTE}/${dto.id}`);
  };

  useEffect(() => {
    if (!openDialog && !openDialogCreate) {
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, openDialog, openDialogCreate, value]);

  const columns: GridColDef[] = [
    {
      field: 'fifoei',
      headerName: '№ ФИФОЕИ',
      renderHeader: () => <strong>№ ФИФОЕИ</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.fifoei,
    },
    {
      field: 'name',
      headerName: 'Наименование',
      renderHeader: () => <strong>Наименование</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'typeDescr',
      headerName: 'Тип',
      renderHeader: () => <strong>Тип</strong>,
      minWidth: 450,
      editable: false,
      renderCell: (row) => row.row.typeDescr,
    },
    {
      field: 'mpi',
      headerName: 'Интервал между поверками',
      renderHeader: () => <strong>Интервал между поверками</strong>,
      minWidth: 310,
      editable: false,
      renderCell: (row) => row.row.mpi,
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 105,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => navigateToModel(row.row)}
          >
            <FindInPageIcon fontSize="small" sx={{ color: '#9c27b0' }} />
          </IconButton>
          <IconButton
            title="Редактировать"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <EditIcon fontSize="small" sx={{ color: '#ff9800' }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDelete(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: '#ff5722' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        marginBottom="20px"
        spacing={2}
      >
        <Stack width="90%">
          <BodyHeaderDiv title={title} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Все" value={SI_TYPE_ALL} />
              <Tab label="Расход" value={SI_TYPE_FLOW} />
              <Tab label="Давление" value={SI_TYPE_PRES} />
              <Tab label="Температура" value={SI_TYPE_TEMP} />
              <Tab
                label="Нормирующий преобразователь"
                value={SI_TYPE_TEMP_NP}
              />
              <Tab label="Барьер искрозащиты" value={SI_TYPE_BAR} />
              <Tab label="Вычислитель" value={SI_TYPE_IVK} />
              <Tab label="ИВК" value={SI_TYPE_IVK_ONLY_CALC} />
            </Tabs>
            <MyButton
              text="Добавить в реестр"
              width="200px"
              background={MAIN_COLOR_STRING}
              onClick={() => setOpenDialogCreate(true)}
            />
          </Stack>
        </Stack>
        <Stack width="90%" spacing={1}>
          <Card
            style={{ padding: '20px', height: '100%', background: '#f5f5f5' }}
          >
            <Box
              height="100%"
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    background: '#f5f5f5',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '5px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={deviceDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={PAGE_SIZE_DEVICES}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Card>
        </Stack>
      </Stack>
      <DialogCreateSi
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogSiMoreInfo
        open={openDialog}
        dto={reestrDelete}
        onClose={() => setOpenDialog(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteReestr}
      />
    </div>
  );
};
