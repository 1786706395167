import i18n from 'i18next';
import {
  FORM_KX_PLUS_B,
  FORM_X_EXP,
  FORM_X_IRVIS_74731_19,
  FORM_X_IRVIS_87977_23,
  FORM_X_LOG,
  FORM_X_POW,
  FORM_X_STEPEN,
  FROM_MAX_DOLI,
  IN_UNIT,
  LANGUAGE_RUS,
  MOBILE_VIEW,
  NORM_FORM_FLOW_ST,
  NORM_FORM_IZM,
  NORM_FORM_OTN_MAX_NASTR,
  NORM_FORM_OTN_NASTR_MAX,
  NORM_FORM_RE,
  NORM_MAIN_ACCUR,
  OUTPUT_0_20MA,
  OUTPUT_4_20MA,
  OUTPUT_CHASTOTA,
  OUTPUT_CIFRA,
  OUTPUT_IMPULSNII,
  OUTPUT_SOPR,
  PAD_VIEW,
  PC_VIEW,
  PEREHODNII_1,
  PEREHODNII_2,
  PEREHODNII_3,
  PEREHODNII_4,
  POGR_ABS,
  POGR_ABS_C,
  POGR_ABS_GERC,
  POGR_ABS_IMP,
  POGR_ABS_MA_0_20,
  POGR_ABS_MA_4_20,
  POGR_ABS_MV,
  POGR_ABS_OM,
  POGR_OTN,
  POGR_PRIV,
  POGR_PRIV_MAX,
  PR_TYPE,
  PRES_UNIT_BAR,
  PRES_UNIT_KGSM2,
  PRES_UNIT_KGSSM2,
  PRES_UNIT_KPA,
  PRES_UNIT_MMRTST,
  PRES_UNIT_MMVODST,
  PRES_UNIT_MPA,
  PRES_UNIT_PA,
  ROOT_NAME,
  SI_SPAN_IRVIS_74731_19,
  SI_SPAN_IRVIS_87977_23,
  SI_TYPE_ALL,
  SI_TYPE_BAR,
  SI_TYPE_FLOW,
  SI_TYPE_IVK,
  SI_TYPE_IVK_ONLY_CALC,
  SI_TYPE_PRES,
  SI_TYPE_TEMP,
  SI_TYPE_TEMP_NP,
  SPAN_TYPE_2,
  SPAN_TYPE_3,
  SPAN_TYPE_4,
  SPAN_TYPE_ALL,
  TEMP_TYPE_DT,
  TEMP_TYPE_TS,
  TEMP_TYPE_TS_NP,
  TEMP_UNIT_C,
  TS_DOPUSK_A,
  TS_DOPUSK_AA,
  TS_DOPUSK_B,
  TS_DOPUSK_C,
  VID_POGR_CALC,
  VID_POGR_CLASS_DOPUSKA_6651,
  VID_POGR_DOP_1,
  VID_POGR_DOP_10,
  VID_POGR_DOP_RAB,
  VID_POGR_MAIN,
  VID_POGR_RAB,
  VID_POGR_TIME,
  VOLUME_UNIT_M,
} from '../constant/constants';
import { AlertSeverityEnum } from '../enum/AlertSeverityEnum';
import { DeviceStageEnum } from '../enum/DeviceStageEnum';
import { IAlertProps } from '../interface/IAlertProps';
import { IApiResult } from '../interface/IApiResult';
import { ComboboxDto } from '../dto/ComboboxDto';
import { DeviceListDto } from '../dto/DeviceListDto';
import { ErrorListDto } from '../dto/error/ErrorLIstDto';

export const getModelFromMany = (modelId?: string, podModel?: string) => {
  if (podModel !== undefined) {
    return parseInt(podModel, 10);
  }
  if (modelId !== undefined) {
    return parseInt(modelId, 10);
  }
  return -1;
};

export const getOutputType = (siType: number) => {
  const outputType: ComboboxDto[] = [];
  if (siType === SI_TYPE_FLOW) {
    outputType.push({
      id: OUTPUT_4_20MA,
      name: '4-20 мА',
    });
    outputType.push({
      id: OUTPUT_IMPULSNII,
      name: 'импульсный',
    });
    outputType.push({
      id: OUTPUT_CHASTOTA,
      name: 'частотный',
    });
    outputType.push({
      id: OUTPUT_CIFRA,
      name: 'цифровой',
    });
  }
  if (siType === SI_TYPE_PRES) {
    outputType.push({
      id: OUTPUT_4_20MA,
      name: '4-20 мА',
    });
    outputType.push({
      id: OUTPUT_0_20MA,
      name: '0-20 мА',
    });
    outputType.push({
      id: OUTPUT_CIFRA,
      name: 'цифровой',
    });
  }
  if (siType === SI_TYPE_TEMP || siType === SI_TYPE_TEMP_NP) {
    outputType.push({
      id: OUTPUT_4_20MA,
      name: '4-20 мА',
    });
    outputType.push({
      id: OUTPUT_SOPR,
      name: 'сопротивление',
    });
    outputType.push({
      id: OUTPUT_CIFRA,
      name: 'цифровой',
    });
  }
  return outputType;
};

export const getPreobrType = () => {
  const preobrType: ComboboxDto[] = [];
  preobrType.push({
    id: SI_TYPE_FLOW,
    name: 'Преобразователь расхода',
  });
  preobrType.push({
    id: SI_TYPE_PRES,
    name: 'Преобразователь давления',
  });
  preobrType.push({
    id: SI_TYPE_TEMP,
    name: 'Преобразователь температуры',
  });
  preobrType.push({
    id: SI_TYPE_TEMP_NP,
    name: 'Нормирующий преобразователь температуры',
  });
  preobrType.push({
    id: SI_TYPE_BAR,
    name: 'Барьер искрозащиты',
  });
  preobrType.push({
    id: SI_TYPE_IVK,
    name: 'Вычислитель',
  });
  preobrType.push({
    id: SI_TYPE_IVK_ONLY_CALC,
    name: 'ИВК',
  });
  return preobrType;
};

export const getNormPreobraz = () => {
  const normPreob: ComboboxDto[] = [];
  normPreob.push({
    id: NORM_FORM_IZM,
    name: 'Измеренное значение',
  });
  normPreob.push({
    id: NORM_FORM_FLOW_ST,
    name: 'Стандартный расход',
  });
  normPreob.push({
    id: NORM_FORM_RE,
    name: 'Число Re',
  });
  normPreob.push({
    id: NORM_FORM_OTN_MAX_NASTR,
    name: 'Отношение максимального к настроенному диапазону',
  });
  normPreob.push({
    id: NORM_FORM_OTN_NASTR_MAX,
    name: 'Отношение настроенного к максимальному диапазону',
  });
  normPreob.push({
    id: NORM_MAIN_ACCUR,
    name: 'Основная погрешность',
  });
  return normPreob;
};

export const getFormTypes = () => {
  const formTypes: ComboboxDto[] = [];
  formTypes.push({
    id: FORM_KX_PLUS_B,
    name: 'k0*x + k1',
  });
  formTypes.push({
    id: FORM_X_POW,
    name: 'k0*x^4 + k1*x^3 + k2*x^2 + k3*x + k4',
  });
  formTypes.push({
    id: FORM_X_EXP,
    name: 'k0*e^(k1*x)',
  });
  formTypes.push({
    id: FORM_X_LOG,
    name: 'k0*ln(x) + k1',
  });
  formTypes.push({
    id: FORM_X_STEPEN,
    name: 'k0*x^k1',
  });
  formTypes.push({
    id: FORM_X_IRVIS_74731_19,
    name: 'ИРВИС-Ультра, 74731-19, ИРВС 9100.0000.00 РЭ7',
  });
  return formTypes;
};

export const getPoddiapazon = (diap: string, index: number, cnt: number) =>
  'от меньешего к большему';
/*
  const arrDiapppa = diap.split(';');
  if (index === 0) {
    return `от min до ${arrDiapppa[0]}`;
  }
  if (index === cnt - 1) {
    return `от ${arrDiapppa[index - 1]} до max`;
  }
  return `от ${arrDiapppa[index - 1]} до ${arrDiapppa[index]}`;
  */

export const getFormTypesSpan = () => {
  const formTypes: ComboboxDto[] = [];
  formTypes.push({
    id: SI_SPAN_IRVIS_74731_19,
    name: 'ИРВИС-Ультра, 74731-19, ИРВС 9100.0000.00 РЭ7',
  });
  return formTypes;
};

export const getVidPogr = (siType: number) => {
  const vidPogr: ComboboxDto[] = [];
  vidPogr.push({
    id: VID_POGR_MAIN,
    name: 'Основная погрешность',
  });
  vidPogr.push({
    id: VID_POGR_DOP_10,
    name: 'Дополнительная погрешность (на каждые 10 °C)',
  });
  vidPogr.push({
    id: VID_POGR_DOP_1,
    name: 'Дополнительная погрешность (на каждый 1 °C)',
  });
  vidPogr.push({
    id: VID_POGR_DOP_RAB,
    name: 'Дополнительная погрешность (в рабочих условиях)',
  });
  vidPogr.push({
    id: VID_POGR_RAB,
    name: 'Погрешность (основная с учетом дополнительной)',
  });

  if (siType === SI_TYPE_IVK || siType === SI_TYPE_IVK_ONLY_CALC) {
    vidPogr.push({
      id: VID_POGR_CALC,
      name: 'Погрешность вычисления',
    });
    vidPogr.push({
      id: VID_POGR_TIME,
      name: 'Погрешность времени',
    });
  }
  if (siType === SI_TYPE_TEMP) {
    vidPogr.push({
      id: VID_POGR_CLASS_DOPUSKA_6651,
      name: 'Класс допуска по ГОСТ 6651-2009',
    });
  }
  return vidPogr;
};

export const getSpanType = () => {
  const spanType: ComboboxDto[] = [];
  spanType.push({
    id: SPAN_TYPE_ALL,
    name: 'Во всем диапазоне',
  });
  spanType.push({
    id: SPAN_TYPE_2,
    name: '2 поддиапазона',
  });
  spanType.push({
    id: SPAN_TYPE_3,
    name: '3 поддиапазона',
  });
  spanType.push({
    id: SPAN_TYPE_4,
    name: '4 поддиапазона',
  });
  return spanType;
};

export const getPerehodniiType = () => {
  const perehodniiType: ComboboxDto[] = [];
  perehodniiType.push({
    id: FROM_MAX_DOLI,
    name: 'В долях от максимального',
  });
  perehodniiType.push({
    id: IN_UNIT,
    name: 'В единицах измерений',
  });
  return perehodniiType;
};

export const getAccurType = (siType: number, isCalc?: boolean) => {
  const accurType: ComboboxDto[] = [];
  if (
    siType === SI_TYPE_FLOW ||
    siType === SI_TYPE_PRES ||
    siType === SI_TYPE_TEMP ||
    siType === SI_TYPE_TEMP_NP
  ) {
    accurType.push({
      id: POGR_ABS,
      name: 'Абсолютная, в ед. изм.',
    });
    accurType.push({
      id: POGR_PRIV,
      name: 'Приведенная (к диапазону), %',
    });
    accurType.push({
      id: POGR_PRIV_MAX,
      name: 'Приведенная (к верхнему пределу), %',
    });
    accurType.push({
      id: POGR_OTN,
      name: 'Относительная, %',
    });
  }

  if (siType === SI_TYPE_BAR || (siType === SI_TYPE_IVK && !isCalc)) {
    accurType.push({
      id: POGR_ABS_MA_4_20,
      name: 'Абсолютная, мА (в диапазоне 4-20 мА)',
    });
    accurType.push({
      id: POGR_ABS_MA_0_20,
      name: 'Абсолютная, мА (в диапазоне 0-20 мА)',
    });
    accurType.push({
      id: POGR_ABS_C,
      name: 'Абсолютная, °C',
    });
    accurType.push({
      id: POGR_ABS_OM,
      name: 'Абсолютная, Ом',
    });
    accurType.push({
      id: POGR_ABS_MV,
      name: 'Абсолютная, мВ',
    });
    accurType.push({
      id: POGR_ABS_GERC,
      name: 'Абсолютная, Гц',
    });
    accurType.push({
      id: POGR_ABS_IMP,
      name: 'Абсолютная, импульс',
    });
    accurType.push({
      id: POGR_PRIV,
      name: 'Приведенная (к диапазону), %',
    });
    accurType.push({
      id: POGR_PRIV_MAX,
      name: 'Приведенная (к верхнему пределу), %',
    });
    accurType.push({
      id: POGR_OTN,
      name: 'Относительная, %',
    });
  }

  if (siType === SI_TYPE_IVK_ONLY_CALC || (siType === SI_TYPE_IVK && isCalc)) {
    accurType.push({
      id: POGR_OTN,
      name: 'Относительная, %',
    });
  }

  return accurType;
};
export const descrError = (errorDto: ErrorListDto) => {
  let errDsecr = '';
  const accurTypes = getAccurType(
    parseInt(localStorage.getItem(PR_TYPE) ?? '-1', 10),
    false
  );
  const formTypes = getFormTypes();
  for (let i = 0; i < errorDto.spanType; i += 1) {
    if (errorDto.data[i].isForm === 1) {
      // eslint-disable-next-line no-use-before-define
      errDsecr = `${errDsecr} ${getNameFromArray(
        formTypes,
        errorDto.data[i].formType
        // eslint-disable-next-line no-use-before-define
      )} - ${getNameFromArray(accurTypes, errorDto.data[i].errorType)};`;
    } else {
      // eslint-disable-next-line no-use-before-define
      errDsecr = `${errDsecr} ${errorDto.data[i].error} - ${getNameFromArray(
        accurTypes,
        errorDto.data[i].errorType
      )};`;
    }
  }
  if (errDsecr !== '') {
    errDsecr = errDsecr.substring(0, errDsecr.length - 1);
  }
  return errDsecr;
};

export const getPoddiapazonType = () => {
  const poddiapazonType: ComboboxDto[] = [];
  poddiapazonType.push({
    id: PEREHODNII_1,
    name: '1',
  });
  poddiapazonType.push({
    id: PEREHODNII_2,
    name: '2',
  });
  poddiapazonType.push({
    id: PEREHODNII_3,
    name: '3',
  });
  poddiapazonType.push({
    id: PEREHODNII_4,
    name: '4',
  });
  return poddiapazonType;
};

export const getCDopuskType = () => {
  const poddiapazonType: ComboboxDto[] = [];
  poddiapazonType.push({
    id: TS_DOPUSK_AA,
    name: 'АА по ГОСТ 6651-2009',
  });
  poddiapazonType.push({
    id: TS_DOPUSK_A,
    name: 'А по ГОСТ 6651-2009',
  });
  poddiapazonType.push({
    id: TS_DOPUSK_B,
    name: 'B по ГОСТ 6651-2009',
  });
  poddiapazonType.push({
    id: TS_DOPUSK_C,
    name: 'C по ГОСТ 6651-2009',
  });
  return poddiapazonType;
};

export const getTempType = () => {
  const tempType: ComboboxDto[] = [];
  tempType.push({
    id: TEMP_TYPE_TS,
    name: 'Термопреобразователь сопротивления (ТС)',
  });
  tempType.push({
    id: TEMP_TYPE_TS_NP,
    name: 'Термопреобразователь сопротивления (ТС) с нормирующим преобразователем (НП)',
  });
  tempType.push({
    id: TEMP_TYPE_DT,
    name: 'Датчик температуры',
  });
  return tempType;
};

export const getUnitType = (siType: number) => {
  const unitType: ComboboxDto[] = [];
  if (siType === SI_TYPE_FLOW) {
    unitType.push({
      id: VOLUME_UNIT_M,
      name: 'м³',
    });
  }
  if (siType === SI_TYPE_PRES) {
    unitType.push({
      id: PRES_UNIT_PA,
      name: 'Па',
    });
    unitType.push({
      id: PRES_UNIT_KPA,
      name: 'кПа',
    });
    unitType.push({
      id: PRES_UNIT_BAR,
      name: 'бар',
    });
    unitType.push({
      id: PRES_UNIT_MPA,
      name: 'МПа',
    });
    unitType.push({
      id: PRES_UNIT_KGSM2,
      name: 'кгс/м²',
    });
    unitType.push({
      id: PRES_UNIT_KGSSM2,
      name: 'кгс/см²',
    });
    unitType.push({
      id: PRES_UNIT_MMRTST,
      name: 'мм рт.ст.',
    });
    unitType.push({
      id: PRES_UNIT_MMVODST,
      name: 'мм вод.ст.',
    });
  }
  if (siType === SI_TYPE_TEMP || siType === SI_TYPE_TEMP_NP) {
    unitType.push({
      id: TEMP_UNIT_C,
      name: '°C',
    });
  }

  return unitType;
};

export const unixToLocal = (unix?: string) => {
  if (unix === undefined) {
    return '';
  }
  const seconds = parseInt(unix, 10) * 1000;
  return new Intl.DateTimeFormat('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(seconds);
};

export const replaceQuoat = (value?: string, type?: number) => {
  if (value === undefined) return '';
  if (type === 0) {
    return value.replaceAll('_', '"');
  }
  return value.replaceAll('"', '_');
};

export const getDeviceStageDescription = (stage: number) => {
  switch (stage) {
    case DeviceStageEnum.ALL:
      return 'Реестр средств измерений';
    default:
      return '';
  }
};

export const getRootLevel = () => {
  const root = parseInt(localStorage.getItem(ROOT_NAME) ?? '-1', 10);
  return root;
};

export const getRootArray = () => {
  const rootArray: string[] = [];
  rootArray.push(i18n.t('read'));
  rootArray.push(i18n.t('readWrite'));
  return rootArray;
};

export const isMobileView = () => {
  const result = window.innerWidth < 820;
  return result;
};

export const otherToDataGrid = {
  autoHeight: true,
  showCellRightBorder: true,
  showColumnRightBorder: true,
};

export const getViewType = () => {
  const result = window.innerWidth;
  if (result < 820) {
    return MOBILE_VIEW;
  }
  if (result < 1440) {
    return PAD_VIEW;
  }
  return PC_VIEW;
};

export const getSelectedLng = () => {
  let lng = localStorage.getItem('language');
  if (lng === null) {
    lng = LANGUAGE_RUS;
  }
  return lng;
};

export const getMainGridSize = () => {
  const result = getViewType();
  if (result === MOBILE_VIEW) {
    return 12;
  }
  if (result === PAD_VIEW) {
    return 6;
  }
  return 3;
};

export const getDefaultAlertProps = () => {
  const alertApp: IAlertProps = {
    message: '',
    severity: AlertSeverityEnum.error,
  };
  return alertApp;
};

export const getDefaultApiResult = () => {
  const apiResult: IApiResult = {
    isSuccess: false,
    status: -1,
    data: null,
    alert: getDefaultAlertProps(),
  };
  return apiResult;
};

export const changeFormatDateWithDiff = (
  inputValue?: string,
  type?: string
) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('.');
    if (arrayDate.length === 3) {
      if (type === undefined) {
        result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
      } else {
        if (type === 'date') {
          result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
        }
        if (type === 'month') {
          result = `${arrayDate[2]}-${arrayDate[1]}`;
        }
        if (type === 'number') {
          result = `${arrayDate[2]}`;
        }
      }
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const formatNumberToBool = (data: string) => {
  if (data === '1') {
    return 'true';
  }
  return '';
};

export const changeFormatDateWithPoint = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('-');
    if (arrayDate.length === 3) {
      result = `${arrayDate[0]}.${arrayDate[1]}.${arrayDate[2]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const setToken = (tokenType: string) =>
  `${localStorage.getItem(tokenType)}`;

export const getSimvol = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getNameArray = (array: ComboboxDto[]) => {
  const arrName = [];
  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].name);
  }
  return arrName;
};

export const getIdArray = (array: ComboboxDto[]) => {
  const arrId = [];
  for (let i = 0; i < array.length; i += 1) {
    arrId.push(array[i].id);
  }
  return arrId;
};

export const getIdFromArray = (array: ComboboxDto[], name: string) => {
  let id = -1;
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].name === name) {
      id = array[i].id;
    }
  }
  return id;
};

export const getNameFromArray = (array: ComboboxDto[], id: number) => {
  let name = '';
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].id === id) {
      name = array[i].name;
    }
  }
  return name;
};

export const formatReestr = (resstr: DeviceListDto[], type: number) => {
  const preobrTypes = getPreobrType();
  const array: DeviceListDto[] = [];
  for (let i = 0; i < resstr.length; i += 1) {
    if (resstr[i].type === type || type === SI_TYPE_ALL) {
      const item: DeviceListDto = resstr[i];
      item.typeDescr = getNameFromArray(preobrTypes, item.type);
      array.push(item);
    }
  }
  return array;
};
